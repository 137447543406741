<template>
  <div>
    <h3> Data Import </h3>
    <p> Procedura per caricare i dati in formato CSV nel sistema Sunny </p>
    <el-steps :active="currentStep" finish-status="success">
      <el-step title="Step 1"></el-step>
      <el-step title="Step 2"></el-step>
      <el-step title="Step 3"></el-step>
    </el-steps>
    <el-card class="box-card" style="margin-top: 15px">
      <h4 style="margin-top: 5px"> Step {{currentStep+1}} </h4>

      <div v-if="currentStep===0">
        <p> Seleziona il tipo di file che si vuole importare </p>
        <el-select v-model="uploadType" placeholder="Seleziona" class="select">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div v-if="currentStep===1">
        <p>Tipo selezionato</p>
        <el-select disabled v-model="uploadType">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <p> File: </p>
        <el-alert type="info" show-icon> I file devono essere csv con ";" come separatore e le intestazioni corrette </el-alert>
        <h4>Esempio intestazioni</h4>
        <p> {{$t("dataImport.csvHeaders."+uploadType)}} </p>
        <el-upload
            style="margin-top: 15px"
            class="select"
            drag
            action=""
            :on-change="handleChange"
            :on-remove="handleRemove"
            :auto-upload="false"
            multiple
            accept=".csv"
            ref="upload"
            :file-list="fileList">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Trascina i file qui o <em>clicca per importare</em></div>
        </el-upload>

      </div>


      <div v-if="currentStep===2">
        <csv-preview v-bind:fileList="fileList"></csv-preview>
      </div>

    </el-card>

    <el-affix position="bottom" :offset="45">
      <el-card shadow="never">
        <el-button type="primary" v-if="currentStep === 0 && uploadType" @click="currentStep++"> Avanti <span class="el-icon-arrow-right"></span> </el-button>
        <div style="display: flex" class="justify-space-between" v-if="currentStep === 1">
          <div>
            <el-button type="primary" v-if="currentStep>0" plain @click="currentStep--"><span class="el-icon-arrow-left"></span>Indietro</el-button>
            <el-button type="primary" v-if="fileList.length>0" @click="currentStep++"> Avanti <span class="el-icon-arrow-right"></span> </el-button>
          </div>
          <el-button type="warning" v-if="fileList.length>0" plain @click="clearAll"><span class="el-icon-delete"></span> Rimuovi tutti i file </el-button>
        </div>
        <div v-if="currentStep===2">
          <el-button type="primary" plain v-if="currentStep>0" @click="currentStep--"><span class="el-icon-arrow-left"></span>Indietro</el-button>
          <el-button type="primary" @click="submitUpload"> Upload <span class="el-icon-upload"></span> </el-button>
        </div>
      </el-card>
    </el-affix>


<!--    <el-button v-if="uploadType!=='' && fileList.length!==0"-->
<!--               type="primary"-->
<!--               @click="openPreviewDialog">-->
<!--      Upload-->
<!--    </el-button>-->

    <csv-preview-dialog @submit="submitUpload" ref="csvPreviewDialog" v-bind:fileList="fileList"></csv-preview-dialog>

<!--    <ul>
      <li v-for="res in resultList" :key="res.id">{{Object.values(res)}}</li>
    </ul>-->


  </div>
</template>

<script>
import FileMixin from "../../mixins/FileMixin";
import AddressMixin from "../../mixins/AddressMixin";
import {mapActions} from "vuex";
import CsvPreviewDialog from "../../components/CsvPreviewDialog";
import CsvPreview from "../../components/CsvPreview";

export default {
  name: "DataImport",
  mixins: [FileMixin, AddressMixin],
  components: {CsvPreviewDialog, CsvPreview},
  data() {
    return {
      currentStep: 0,
      extraTip: "",
      //file: null,
      completed: false,
      fileList: [],
      csvObjects: [],
      options: [
        {
          value: 'users',
          label: 'Utenti',
        },
        {
          value: 'condominia',
          label: 'Condomini',
        },
        {
          value: 'apartments',
          label: 'Appartamenti',
        },
        {
          value: 'meters',
          label: 'Contatori',
        },
        {
          value: 'readings',
          label: 'Letture',
        },
      ],
      uploadType: '',
      resultList: [],
    }
  },
  watch: {
    uploadType: function (){
      //console.log(this.uploadType);
      if(this.uploadType === "readings"){
        this.extraTip = "Prima riga obbligatoria con \"dateTime;serialNumber;value;error\". La data deve essere in formato GG/MM/AAAA";
      } else {
        this.extraTip = ""
      }
    },
  },
  methods: {
    ...mapActions(["registerUser", "createCondominium", "createApartment", "createMeterType", "createMeter", "createReading", "startForceLoading", "stopForceLoading"]),
    next() {
      if (this.active++ > 2) this.active = 0
    },
    async submitUpload() {
      this.startForceLoading();
      this.completed = false;
      for (const file of this.fileList) {
        const text = await this.readTextFile(file.raw);
        const dataArray = await this.CSVToObj(text, ";");
        this.resultList = [];
        switch (this.uploadType) {
          case "users":
            for (let data of dataArray) {
              this.resultList.push(await this.registerUser(data));
            }
            break;
          case "condominia":
            for (let data of dataArray) {
              this.resultList.push(await this.createCondominium(data));
            }
            break;
          case "apartments":
            for (let data of dataArray) {
              this.resultList.push(await this.createApartment(data));
            }
            break;
          case "meterTypes":
            for (let data of dataArray) {
              this.resultList.push(await this.createMeterType(data));
            }
            break;
          case "meters":
            for (let data of dataArray) {
              this.resultList.push(await this.createMeter(data));
            }
            break;
          case "readings":
            for (let data of dataArray) {
              this.resultList.push(await this.createReading(data));
            }
            break;
          default:
            break;
        }
      }
      this.stopForceLoading();

      this.currentStep = 0;
      this.clearAll()

    },
    async openPreviewDialog() {
      await this.$refs.csvPreviewDialog.show();
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    clearAll(){
      this.fileList = []
      this.$refs.upload.clearFiles();
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
  }
}
</script>

<style scoped>
.select{
  width: 100%;
  margin-bottom: 15px;
}
</style>
